<template>
    <div class="content-wrapper">
        <h1>{{ $t('Edit Profile') }}</h1>

        <form @submit.prevent="updateProfile">

            <div class="profile-image">
                <div class="image">
                    <img src="@/assets/img/candidate-mobile-1.png" :alt="user.profile.first_name" />
                </div>
            </div>

            <div v-if="changeImage || !user.profile.image">
                <div class="input-group">
                    <div class="custom-file">
                        <label class="custom-file-label" for="image">{{ file?.name || 'Choose file' }}</label>
                        <input type="file" class="custom-file-input" id="image" accept="image/png,image/jpg,image/jpeg"
                            @change="file = $event.target.files[0]">
                    </div>
                </div>
                <FormErrors v-if="errors[`image`]" :errors="errors[`image`]" />
            </div>

            <div v-else class="form-group row">
                <a @click.prevent="changeImage=true" href=""><h2 class="text-success">Change File</h2></a>
                <img class=" ml-4 mt-1" :src="user.profile.image" alt="img" style="max-height: 18rem; max-width: 18rem; object-fit: scale-down;">
            </div>

            <div :class="['labeled-icon-input', {'has-error': errors[`profile.first_name`]}]">
                
                <div class="form-group">
                    <label for="first_name">{{ $t('First name') }}</label>
                    <input v-model="user.profile.first_name" class="form-control" type="text" id="first_name">
                    <label for="first_name" class="icon-label">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                            <g>
                                <g>
                                    <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                </g>
                            </g>
                        </svg>
                    </label>
                </div>

                <FormErrors v-if="errors[`profile.first_name`]" :errors="errors[`profile.first_name`]" />

            </div>

            <div :class="['labeled-icon-input', {'has-error': errors[`profile.last_name`]}]">
                <div class="form-group">
                    <label for="last_name">{{ $t('Last name') }}</label>
                    <input v-model="user.profile.last_name" class="form-control" type="text" id="last_name">
                    <label for="last_name" class="icon-label">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                            <g>
                                <g>
                                    <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                </g>
                            </g>
                        </svg>
                    </label>
                </div>

                <FormErrors v-if="errors[`profile.last_name`]" :errors="errors[`profile.last_name`]" />

            </div>
            <div :class="['labeled-icon-input', {'has-error': errors[`profile.phone_number`]}]">
                <div class="form-group">
                    <label for="phone_number">{{ $t('Phone') }}</label>
                    <input v-model="user.profile.phone_number" class="form-control" type="text" id="phone_number" required>
                    <label for="phone_number" class="icon-label">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                            <g>
                                <g>
                                    <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                </g>
                            </g>
                        </svg>
                    </label>
                </div>
                
                <FormErrors v-if="errors[`profile.phone_number`]" :errors="errors[`profile.phone_number`]" />

            </div>

            <div :class="['labeled-icon-input', {'has-error': errors[`profile.language_id`]}]">
                <div class="form-group">
                    <label>{{ $t('Language') }}</label>
                    <Multiselect
                        v-model="user.profile.language_id"
                        :options="languages"
                        :placeholder="$t('Select language')"
                        class="form-control"
                        :searchable="true"
                    />
                </div>
                
                <FormErrors v-if="errors[`profile.language_id`]" :errors="errors[`profile.language_id`]" />

            </div>

            <div :class="['labeled-icon-input', {'has-error': errors[`profile.country_id`]}]">
                <div class="form-group">
                    <label>{{ $t('Country') }}</label>
                    <Multiselect
                        v-model="user.profile.country_id"
                        :options="countries"
                        :placeholder="$t('Select country')"
                        class="form-control"
                        :searchable="true"
                    />
                </div>

                <FormErrors v-if="errors[`profile.country_id`]" :errors="errors[`profile.country_id`]" />

            </div>

            <div v-if="changePassword" :class="['labeled-icon-input', {'has-error': errors[`password`]}]">
                <div class="form-group">
                    <label for="password">{{ $t('Password') }}</label>
                    <input v-model="user.password" type="password" class="form-control" id="password">
                    <div class="icon"></div>
                </div>
                
                <FormErrors v-if="errors[`password`]" :errors="errors[`password`]" />
            </div>

            <p class="change-password" v-else @click.prevent="changePassword=true">{{ $t('Click here to change password') }}</p>

            <button class="btn btn-primary" type="submit">{{ $t('Save') }}</button>
        </form>
    </div>
</template>

<script>
import {userService} from "@/services/userService";
import formDataAppend from "@/js/formDataAppend";
import FormErrors from "@/components/widgets/FormErrors";
import Multiselect from '@vueform/multiselect'

export default {
    name: "EditProfileComponent",
    data() {
        return {
            user: {
                profile: {}
            },
            changePassword: false,
            changeImage: false,
            file: null,
        }
    },
    components: {
        FormErrors,
        Multiselect
    },
    computed: {
        countries() {
            return this.$store.getters.countries
        },
        languages() {
            return this.$store.getters.languages
        }
    },
    created() {
        userService.getProfile().then(response => this.user = response.data.data)
    },
    methods: {
        updateProfile() {
            const formData = new FormData();

            if (this.file) {
                formData.append('image', this.file)
            }

            formDataAppend(formData, this.user)

            if (this.changeImage) {
                formData.append('delete_image', 'true')
            }

            userService.updateProfile(formData)
                .then(response => {
                    this.errors = []
                    this.changeImage = false
                    this.user.profile = response.data.data
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
        }
    }
}
</script>